import "../css/login.css";

export default function FooterSmall() {
  return (
    <div className="login-footer">
      <p className="smallfooter">Copyright © Dance4Healing 2025</p>
      <p className="smallfooter">
        Best viewed in <a href="https://www.google.com/chrome/browser/desktop/">Chrome web browser.</a>
      </p>
    </div>
  );
}
