import React, { Component } from "react";
import { Link } from "react-router-dom";
import login_facebook from "../assets/img/btn_login_facebook.png";
import login_twitter from "../assets/img/btn_login_twitter.png";
import login_google from "../assets/img/btn_login_google.png";
import login_linkedin from "../assets/img/btn_login_linkedin.png";
import * as usersAPI from "../utils/usersAPI";
import SignupForm from "./SignupForm";
import Welcome from "./Welcome";
import "../assets/css/welcome.scoped.css";
import "../assets/css/bootstrap.scoped.css";
import NavBar from "./NavBar";
const earlyAccessEnabled =
  process.env.REACT_APP_EARLY_ACCESS_ENABLED === "true";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.handleErrorMessageChange = this.handleErrorMessageChange.bind(this);
    this.state = {
      ErrorMessage: "",
    };
  }

  componentDidMount() {
    usersAPI.getWelcomeMessage().then((ErrorMessage) => {
      this.setState({ ErrorMessage });
    });
  }

  handleErrorMessageChange(ErrorMessage) {
    this.setState({ ErrorMessage });
    window.scrollTo(0, 0);
  }
  getToken() {
    const tokenString = window.localStorage.getItem("token");
    return tokenString;
  }
  getAuth() {
    const stat = window.localStorage.getItem("authenticated");
    return stat;
  }

  render() {
    let token = this.getToken();
    let auth = this.getAuth();
    if (token != null && auth) {
      return <Welcome />;
    }
    return (
      <>
        <NavBar />

        <div
          style={{
            marginTop: 50,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
          }}
          className="container"
        >
          <h1 style={{ fontFamily: '"Nunito", sans-serif' }}>Join the Party</h1>
          <p
            style={{
              fontSize: 21,
              fontFamily: '"Nunito", sans-serif',
              fontWeight: 300,
              fontStyle: "italic",
            }}
          >
            Dance for healthier, happier, smarter living,
          </p>
          <p
            style={{
              fontSize: 21,
              paddingBottom: 20,
              fontFamily: '"Nunito", sans-serif',
              fontWeight: 300,
              fontStyle: "italic",
            }}
          >
            with anyone, anytime, anywhere in the world.
          </p>
          {!earlyAccessEnabled && (
            <div>
              <p
                style={{
                  fontFamily: '"Nunito", sans-serif',
                  paddingBottom: 20,
                }}
              >
                Join with your social account
              </p>
              <p className="error" />
              <div className="icons" style={{ paddingBottom: 10 }}>
                <a href="/users/facebook">
                  <img src={login_facebook} alt="" />
                </a>
                {/* <a href="/users/twitter"><img src={login_twitter} alt="" /></a> */}
                <a href="/auth/google">
                  <img src={login_google} alt="" />
                </a>
                <a href="/users/linkedin">
                  <img src={login_linkedin} alt="" />
                </a>
              </div>
            </div>
          )}
          <p
            style={{
              paddingBottom: 12,
              color: "white",
              fontFamily: '"Roboto", sans-serif',
              fontSize: 24,
              fontWeight: 300,
              letterSpacing: 1,
            }}
          >
            {this.state.ErrorMessage}
          </p>
          <SignupForm onMessageChange={this.handleErrorMessageChange} />
        </div>
        <footer>
          <p className="smallfooter">Copyright © Dance4Healing 2023</p>
          <p className="smallfooter">
            Best viewed in{" "}
            <a href="https://www.google.com/chrome/browser/desktop/">
              Chrome web browser.
            </a>
          </p>
        </footer>
      </>
    );
  }
}

export default Signup;
