import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import "../css/login.css";
import alertImg from "../assets/img/exclamation-triangle.svg";
import NavBar from "./NavBar";
import SocialLogin from "./SocialLogin";
import FooterSmall from "./FooterSmall";

import { resetPass } from "../utils/usersAPI";
import serializeForm from "form-serialize";

function PasswordReset(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [message, setMessage] = React.useState(null);
  const [smallMessage, setSmallMessage] = useState("");
  const [userEmail, setUserEmail] = useState(null);

  const onSubmit = async (data) => {
    const result = await resetPass(data);
    const response = await result.json();

    if (result.ok) {
      setSmallMessage(response.message);
    } else {
      setSmallMessage(response.errorMessage || "There was an unexpected error.  Please try again.");
    }
  };

  return (
    <div>
      <NavBar />
      <div className="login-container">
        <h1>Forgot Password?</h1>
        <p
          style={{
            marginTop: 30,
            marginBottom: 30,
            fontFamily: '"Lato", sans-serif',
            fontStyle: "italic",
            fontSize: 24,
            fontWeight: 300,
            letterSpacing: 1,
          }}
        >
          Don't panic, we'll send you a link to reset your password.
        </p>

        {smallMessage ? (
          <p
            style={{
              border: "2px solid #e7f036",
              borderRadius: "6px",
              marginTop: 20,
              marginBottom: 20,
              padding: 15,
              fontFamily: '"Roboto", sans-serif',
              fontSize: 18,
              fontWeight: 300,
              letterSpacing: 1,
            }}
          >
            {smallMessage}
          </p>
        ) : null}
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="username" className="form-label">
              Email or Username
            </label>
            <input
              id="username"
              name="username"
              type="text"
              placeholder="Enter Email or Username"
              className={errors?.username ? "form-control input-error" : "form-control"}
              {...register("username", {
                required: "Email or Username is required",
              })}
            />
            {errors.username && (
              <div className="error-message">
                <img className="error-img" src={alertImg} />
                <span>{errors.username.message}</span>
              </div>
            )}
          </div>

          <button type="submit" name="submit" style={{ marginTop: 36 }} className="login-btn">
            Reset Password
          </button>
        </form>
        <SocialLogin heading="or Log in with your social account" />
      </div>
      <FooterSmall />
    </div>
  );
}

export default PasswordReset;
