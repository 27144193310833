import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavBar from "../NavBar";
import ResentVerification from "./ResentVerification";
import "../../css/confirmEmail.css";
import login_facebook from "../../assets/img/btn_login_facebook.png";
import login_twitter from "../../assets/img/btn_login_twitter.png";
import login_google from "../../assets/img/btn_login_google.png";
import login_linkedin from "../../assets/img/btn_login_linkedin.png";
import { sendConfirm } from "../../utils/usersAPI";

const NotVerified = () => {
  const { userEmail } = useParams();

  const [errorMessage, setErrorMessage] = useState(null);
  const [resentSuccess, setResentSuccess] = useState(false);
  const [displayResendMessage, setDisplayResendMessage] = useState(true);
  const [displayAlreadyConfirmed, setDisplayAlreadyConfirmed] = useState(false);
  const [email, setEmail] = useState(null);

  const alreadyConfirmedMessage = () => {
    return (
      <div className="success-div">
        <div>Your email is already confirmed - thank you!</div>
        <div>You can begin your Dance4Healing journey by logging into your account.</div>
        <Link to="/login" className="confirm-btn">
          Log In
        </Link>
      </div>
    );
  };

  const resendMessage = () => {
    return (
      <>
        <h3 style={{ textAlign: "center" }}>You have not confirmed your email yet!</h3>
        <div>Dance4Healing ensures high-quality connections.</div>
        <br />
        <div>Please go to your mailbox and confirm your email address to access your account.</div>
        <br />
        <div>
          Check your spam if you don't see it. If needed, please click{" "}
          <button onClick={handleResendConfirm} className="confirm-btn">
            Resend
          </button>{" "}
          to resend verification.
        </div>
        <div style={{ textAlign: "center" }}>
          <p>Meanwhile, follow us on these social media websites down below!</p>
          <div className="icons">
            <a href="https://www.facebook.com/danceforhealing">
              <img src={login_facebook} alt="Facebook" />
            </a>
            <a href="http://www.twitter.com/dance4healing">
              <img src={login_twitter} alt="Twitter" />
            </a>
            <a href="https://www.linkedin.com/company/dance4healing">
              <img src={login_linkedin} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </>
    );
  };

  const errorNoEmailMessage = () => {
    return (
      <div className="success-div">
        <div>There is an error confirming your email.</div>
        <div>Please login and choose to resend email confirmation if prompted.</div>
        <div>If you continue to run into errors, please email hello@dance4healing.com for assistance. Thank you!</div>
        <Link to="/login" className="confirm-btn">
          Log In
        </Link>
      </div>
    );
  };

  const handleResendConfirm = async () => {
    const result = await sendConfirm(userEmail);
    console.log("not verified resent result: ", result);
    if (result.email) {
      setEmail(result.email);
    }
    if (result.result === "Success") {
      setErrorMessage(null);
      setDisplayResendMessage(false);
      setResentSuccess(true);
    } else if (result.result === "Already Confirmed") {
      setErrorMessage(null);
      setResentSuccess(false);
      setDisplayResendMessage(false);
      setDisplayAlreadyConfirmed(true);
    } else {
      setResentSuccess(false);
      setErrorMessage(result.message);
    }
  };

  return (
    <div className="confirm">
      <NavBar />
      <div className="not-verified-container">
        {errorMessage ? <div>{errorMessage}</div> : null}
        {displayResendMessage && resendMessage()}

        {errorMessage && email && resendMessage()}
        {errorMessage && !email && errorNoEmailMessage()}
        {resentSuccess && <ResentVerification email={email} />}
        {displayAlreadyConfirmed && alreadyConfirmedMessage()}
      </div>
    </div>
  );
};

export default NotVerified;
