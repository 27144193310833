import { NavLink } from "react-router-dom";
// import "../assets/css/bootstrap.scoped.css";
import "../css/navBar.css";
import logo from "../assets/img/d4h_logo.png";
import { I18Picker } from "./I18nIntl";
import { HelpLogo, HelpInfoLogo } from "./helpsupport/HelperIcons";
import { HelpMenuList } from "./helpsupport/HelpMenus";
const NavBar = () => {
  return (
    <nav className="navbar">
      <NavLink
        className={({ isActive }) =>
          isActive ? "navlink active" : "navlink inactive"
        }
        to="/"
      >
        <img src={logo} />
      </NavLink>
      {/* <div
        className="navbar-brand"
        style={{ position: "relative", cursor: "pointer" }}
        >
        <HelpMenuList />
        </div> */}

      <NavLink
        className={({ isActive }) =>
          isActive ? "navlink active" : "navlink inactive"
        }
        to="/register"
      >
        Join the Party
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive ? "navlink active" : "navlink inactive"
        }
        to="/login"
      >
        Log In
      </NavLink>
    </nav>
  );
};

export default NavBar;
