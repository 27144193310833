import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import Signup from "./components/Signup";
import SignupNew from "./components/SignupNew";
import SignupSuccess from "./components/SignupSuccess";
import Home from "./components/Home";
import ConfirmEmail from "./components/login/ConfirmEmail";
import NotVerified from "./components/login/NotVerified";
import Welcome from "./components/Welcome";
import UserProfile from "./components/UserProfile";
import PasswordReset from "./components/PasswordReset";
import ResetSuccess from "./components/ResetSuccess";
import VideoContainer from "./components/video/VideoContainer";
import PrivateRoutes from "./utils/PrivateRoutes";
import { FinishedDancingModal } from "./components/emotion/FinishedDancingModal";
import { Suspense, lazy, useEffect, useState } from "react";
import i18n from "./i18n"; //need to import for initialization
import i18next from "i18next"; //need to import for initialization
import { HelpFAQPage } from "./components/helpsupport/HelpDocs";
import { WithHelperDocs } from "./components/helpsupport/HelpMenus";

import { VideoHelpProvider } from "./components/helpsupport/VideoTour";
function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/welcome"
            element={
              <WithHelperDocs startTour={false}>
                <Welcome />
              </WithHelperDocs>
            }
          />
          <Route
            path="/video"
            element={
              <VideoHelpProvider>
                <VideoContainer />
              </VideoHelpProvider>
            }
          />
          <Route
            path="/profile"
            element={
              <WithHelperDocs startTour={false}>
                <UserProfile />
              </WithHelperDocs>
            }
          />
        </Route>

        <Route path="/" exact element={<Home />} />
        <Route path="/confirm/:token" element={<ConfirmEmail />} />
        <Route
          path="/login/:userId"
          element={
            <WithHelperDocs startTour={false}>
              <Login />
            </WithHelperDocs>
          }
        />
        <Route
          path="/login"
          element={
            <WithHelperDocs startTour={false}>
              <Login />
            </WithHelperDocs>
          }
        />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route
          path="/register"
          element={
            <WithHelperDocs startTour={false}>
              <SignupNew />
            </WithHelperDocs>
          }
        />
        <Route path="/register/success/:email" element={<SignupSuccess />} />
        <Route path="/reset/:token" element={<ResetSuccess />} />
        <Route path="/email/confirm/:userEmail" element={<NotVerified />} />
        <Route path="/help/FAQ" element={<HelpFAQPage />} />
        <Route path="/help" element={<HelpFAQPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
