import { Link, Navigate, useParams } from "react-router-dom";
import NavBar from "./NavBar";
import "../css/signup.css";
import danceQuote from "../assets/img/dance-quote-1.png";
import login_facebook from "../assets/img/btn_login_facebook.png";
import login_twitter from "../assets/img/btn_login_twitter.png";
import login_linkedin from "../assets/img/btn_login_linkedin.png";

export default function SignupSuccess() {
  const { email } = useParams();
  return (
    <div id="signup-success-page">
      <NavBar />
      <div className="signup-success-container">
        <div className="signup-success-column" id="signup-success-left-side">
          <img id="register-dance-img" src={danceQuote} />

          <div id="signup-success-social-container">
            <div id="social-container-header">Connect with us on Social Media</div>
            <div className="signup-success-icons">
              <a href="https://www.facebook.com/danceforhealing?fref=ts">
                <img src={login_facebook} alt="Facebook" />
              </a>
              <a href="http://www.twitter.com/dance4healing">
                <img src={login_twitter} alt="Twitter" />
              </a>
              <a href="https://www.linkedin.com/company/dance4healing">
                <img src={login_linkedin} alt="LinkedIn" />
              </a>
            </div>
            <a id="social-container-website-link" href="https://www.dance4healing.com" target="_blank">
              Dance4Healing.com
            </a>
          </div>
        </div>
        <div className="signup-success-column" id="signup-success-right-side">
          <div id="success-right-upper">
            <h1>Thank you for signing up for Dance4Healing!</h1>
            <p>We can't wait to dance with you.</p>

            <p>We've sent a verification email to your email: {email}.</p>
            <p>Please verify that your email is correct.</p>
            <p style={{ fontSize: "1rem" }}>(Register again if there is a misspelling.)</p>
            <p style={{ fontWeight: "700" }}>Click on the activation link in your inbox.</p>
            <p>If you haven't received this email, please wait a few minutes.</p>
            <p>Be sure to check your spam if you don't see it in your inbox.</p>
            <p>Still having trouble? Email hello@dance4healing.com for help.</p>
          </div>
          <div id="success-right-lower">
            <p>
              We respect your privacy. Here is our{" "}
              <a
                href="https://www.dance4healing.com/privacy.pdf"
                style={{ color: "black", textDecoration: "underline" }}
              >
                Privacy Policy.
              </a>
            </p>
            <p className="footer-legal">Copyright © Dance4Healing 2024</p>
            <p className="footer-legal">
              Best viewed in{" "}
              <a style={{ color: "black" }} href="https://www.google.com/chrome/browser/desktop/">
                Chrome web browser.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
