import "../css/socialLogin.css";
import login_facebook from "../assets/img/btn_login_facebook.png";
import login_twitter from "../assets/img/btn_login_twitter.png";
import login_google from "../assets/img/btn_login_google.png";
import login_linkedin from "../assets/img/btn_login_linkedin.png";
import { apiUrl } from "../utils/utils";

export default function SocialLogin({ heading }) {
  return (
    <div className="social-container">
      <p
        style={{
          fontFamily: '"Nunito", sans-serif',
          fontSize: "21px",
          paddingBottom: 5,
        }}
      >
        {heading}
      </p>
      <p className="error" />
      <div className="social-icons" style={{ paddingBottom: 5 }}>
        <a href="/users/facebook">
          <img src={login_facebook} alt="" />
        </a>
        {/* <a href="/users/twitter"><img src={login_twitter} alt="" /></a> */}
        <a href={apiUrl() + "/auth/google"}>
          <img src={login_google} alt="" />
        </a>
        <a href={apiUrl() + "/auth/linkedin"}>
          <img src={login_linkedin} alt="" />
        </a>
      </div>
    </div>
  );
}
