import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../css/login.css";
import NavBar from "../NavBar";
import SocialLogin from "../SocialLogin";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import alertImg from "../../assets/img/exclamation-triangle.svg";
import { loginUser } from "../../utils/usersAPI";
import FooterSmall from "../FooterSmall";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [searchParams] = useSearchParams();
  const socialError = searchParams.get("error");

  const [smallMessage, setSmallMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [notUserVerified, setNotUserVerified] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    if (socialError && socialError === "auth_failed") {
      setSmallMessage("You cannot register using social media - please complete registration first.");
    }
  }, []);

  const formatErrors = (errors) => {
    return (
      <ul>
        {errors.map((error) => {
          return <li style={{ textAlign: "left" }}>{error.msg}</li>;
        })}
      </ul>
    );
  };

  const onSubmit = async (data) => {
    //console.log("Form Data: ", data);

    const result = await loginUser(data);
    const response = await result.json();
    //console.log("Login Response: ", response);
    localStorage.setItem("fname", response.user?.fname);

    if (result.ok) {
      setLoggedIn(true);
    } else if (result.status === 403) {
      setUserEmail(response.user?.email);
      console.log("email", userEmail, response.user);
      setNotUserVerified(true);
    } else {
      if (response?.message) {
        setSmallMessage(response.message);
      } else if (response?.errors) {
        setSmallMessage(formatErrors(response.errors));
      } else if (response?.errorMessage) {
        setSmallMessage(response.errorMessage);
      } else {
        setSmallMessage("There was a problem with your login - please try again");
      }
    }
  };

  if (loggedIn) {
    return <Navigate to="/welcome" />;
  } else if (notUserVerified) {
    return <Navigate to={`/email/confirm/${userEmail}`} />;
  } else {
    return (
      <>
        <NavBar />
        <div className="login-container">
          <h1>Log In</h1>
          <SocialLogin heading="Log in with your social account" />
          {smallMessage ? (
            <p
              style={{
                border: "2px solid #e7f036",
                borderRadius: "6px",
                marginTop: 20,
                marginBottom: 20,
                padding: 15,
                fontFamily: '"Roboto", sans-serif',
                fontSize: 18,
                fontWeight: 300,
                letterSpacing: 1,
              }}
            >
              {smallMessage}
            </p>
          ) : null}
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                placeholder="Enter Username or Email"
                className={errors?.username ? "form-control input-error" : "form-control"}
                {...register("username", {
                  required: "Username is required",
                })}
              />
              {errors.username && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.username.message}</span>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                in="password"
                name="password"
                type="password"
                placeholder="Enter Password"
                className={errors?.password ? "form-control input-error" : "form-control"}
                {...register("password", {
                  required: "Password is required",
                })}
              />
              {errors.password && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.password.message}</span>
                </div>
              )}
            </div>
            <div
              style={{
                marginTop: 20,
                paddingBottom: 12,
                fontSize: 14,
                fontFamily: '"Roboto", sans-serif',
              }}
            >
              <Link to="/passwordreset" style={{ marginTop: "-10px" }}>
                Forgot your password?
              </Link>
            </div>
            {/* harder font, like open sans or montserrat*/}
            <button
              type="submit"
              name="submit"
              style={{ marginTop: 36 }}
              // style={{ marginTop: 36, marginLeft: "-120px" }}
              className="login-btn"
            >
              Let's Dance!
            </button>
            <p
              style={{
                textAlign: "center",
                paddingTop: 18,
                fontSize: 20,
                paddingBottom: 18,
                borderTopStyle: "dotted",
                borderWidth: 2,
                marginTop: 24,
                fontFamily: '"Roboto", sans-serif',
                fontWeight: 300,
                letterSpacing: 1,
              }}
            >
              No account and want to dance?
            </p>
            <a href="/register" role="button" className="login-btn">
              Join the Party
            </a>
          </form>
        </div>
        <FooterSmall />
      </>
    );
  }
};

export default Login;
