import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../css/signup.css";
import NavBar from "./NavBar";
import FooterSmall from "./FooterSmall";
import SocialLogin from "./SocialLogin";
import SignupSuccess from "./SignupSuccess";
import alertImg from "../assets/img/exclamation-triangle.svg";
import { registerUser } from "../utils/usersAPI";
const earlyAccessEnabled = process.env.REACT_APP_EARLY_ACCESS_ENABLED === "true";

export default function SignupNew() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [redirect, setRedirect] = useState(null);
  const [responseError, setResponseError] = useState(null);

  const password = watch("password");
  const password2 = watch("password2");

  const onSubmit = async (data) => {
    setResponseError(null);
    console.log("Form Data:", data);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    const response = await registerUser(data);
    console.log("register response: ", response);
    if (response.message === "Signup Successful!") {
      setRedirect(`/register/success/${response.email}`);
    } else {
      if (response.errors) {
        const errorMessages = response.errors.map((error, index) => {
          if (index > 0) {
            return ` | ${error.msg}`;
          }
          return `${error.msg}`;
        });
        console.log(errorMessages);
        setResponseError(errorMessages);
      } else {
        setResponseError("There was an error");
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    // if (res.error) {
    //   const element = <p className="borderError">{res.error}</p>;
    //   this.props.onMessageChange(element);
    // } else {
    //   const element = res.error;
    //   this.props.onMessageChange(element);
    // }

    // if ("details" in res) {
    //   if (res.details[0].param === "password") {
    //     this.setState({ passError: res.details[0].msg });
    //   }
    // }

    // if (
    //   res != null &&
    //   "Message" in res &&
    //   res.Message === "Signup successfully!"
    // ) {
    //   setTimeout(() => {
    //     this.setState({ redirect: "/login" });
    //   }, 2500);
    //   this.setState({ confirmation: "block" });
    // }
  };

  if (redirect) {
    return <Navigate replace to={redirect} />;
  } else {
    return (
      <div>
        <NavBar />
        <div className="signup-container">
          {responseError && (
            <div className="error-banner">
              <span>{responseError}</span>
            </div>
          )}
          {Object.keys(errors).length > 0 && (
            <div className="error-banner">
              <img className="error-img" src={alertImg} />
              <span>There are items that require your attention</span>
            </div>
          )}
          <h1>Join the Party</h1>
          <div className="headline">Dance for healthier, happier, smarter living</div>
          <div className="headline">with anyone, anytime, anywhere in the world.</div>
          {!earlyAccessEnabled && <SocialLogin heading="Join with your social account" />}
          <div className="welcome">Smile, Sign up, Dance, Have Fun!</div>
          <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
            {earlyAccessEnabled && (
              <div className="signup-form-group">
                <label htmlFor="early_access_code">Early Access Code</label>
                <input
                  id="early_access_code"
                  name="early_access_code"
                  type="text"
                  placeholder="Enter Early Access Code"
                  className={errors?.early_access_code ? "signup-form-control input-error" : "signup-form-control"}
                  {...register("early_access_code", {
                    required: "Access Code is required",
                  })}
                />
                {errors.early_access_code && (
                  <div className="error-message">
                    <img className="error-img" src={alertImg} />
                    <span>{errors.early_access_code.message}</span>
                  </div>
                )}
              </div>
            )}
            <div className="signup-form-group">
              <label htmlFor="fname">First Name</label>
              <input
                id="fname"
                name="fname"
                type="text"
                placeholder="Enter First Name"
                className={errors?.fname ? "signup-form-control input-error" : "signup-form-control"}
                {...register("fname", {
                  required: "First name is required",
                  maxLength: {
                    value: 30,
                    message: "First name can not be longer than 30 characters",
                  },
                })}
              />
              {errors.fname && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.fname.message}</span>
                </div>
              )}
            </div>
            <div className="signup-form-group">
              <label htmlFor="lname">Last Name</label>
              <input
                id="lname"
                name="lname"
                type="text"
                placeholder="Enter Last Name"
                {...register("lname", {
                  required: "Last name is required",
                  maxLength: {
                    value: 30,
                    message: "Last name can not be longer than 30 characters",
                  },
                })}
                className={errors?.lname ? "signup-form-control input-error" : "signup-form-control"}
              />
              {errors.lname && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.lname.message}</span>
                </div>
              )}
            </div>
            <div className="signup-form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                className={errors?.email ? "signup-form-control input-error" : "signup-form-control"}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please check email address format",
                  },
                })}
              />
              {errors.email && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.email.message}</span>
                </div>
              )}
            </div>
            <div className="signup-form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Enter Password"
                className={errors?.password ? "signup-form-control input-error" : "signup-form-control"}
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
                    message: "Password does not meet requirements",
                  },
                })}
              />
              <span className="password-message" id="message1">
                Password requirements are:
                <br /> Between 8 and 30 characters.
                <br /> At least 1 small letter.
                <br /> At least 1 capital letter.
                <br /> At least 1 number.
                <br /> At least 1 symbol.
              </span>
              {errors.password && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.password.message}</span>
                </div>
              )}
            </div>
            <div className="signup-form-group">
              <label htmlFor="password2">Confirm Password</label>
              <input
                id="password2"
                name="password2"
                type="password"
                placeholder="Confirm Password"
                className={errors?.password2 ? "signup-form-control input-error" : "signup-form-control"}
                {...register("password2", {
                  required: "Confirm password is required",
                  validate: (value) => value === password || "Confirm password does not match",
                })}
              />
              {errors.password2 && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.password2.message}</span>
                </div>
              )}
              {!errors.password2 && password && password2 && password !== password2 && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>Confirm password does not match</span>
                </div>
              )}
            </div>
            <div className="signup-form-group">
              <label htmlFor="affiliation">Type your Affiliation</label>
              <input
                id="affiliation"
                name="affiliation"
                type="text"
                placeholder="Type in your organization name if you belong to one"
                className="signup-form-control"
                {...register("affiliation")}
              />
            </div>

            <div className="signup-form-checkbox" style={{ marginTop: "1rem", fontWeight: "bold" }}>
              <input type="checkbox" id="instructor" name="instructor" {...register("instructor")} /> Are you an
              instructor that wants to teach?
            </div>
            <div className="signup-form-checkbox">
              <input
                type="checkbox"
                id="newsletter"
                name="newsletter"
                style={{ paddingTop: 25, marginTop: 15 }}
                {...register("newsletter")}
              />{" "}
              Sign me up for the newsletter, I like to stay informed.
            </div>

            <div className="signup-form-checkbox">
              <input
                type="checkbox"
                id="toagree"
                name="toagree"
                style={{ paddingTop: 25, marginTop: 15 }}
                {...register("toagree", {
                  required: "You must agree to the terms of service",
                })}
              />{" "}
              I agree to the Dance4Healing{" "}
              <a href="/terms.pdf" style={{ textDecoration: "underline" }}>
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy.pdf" style={{ textDecoration: "underline" }}>
                {" "}
                Privacy Policy
              </a>
              .
              {errors.toagree && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.toagree.message}</span>
                </div>
              )}
            </div>
            <br />

            <button type="submit" name="submit" id="submit" className="signup-btn">
              Sign Up Now
            </button>
          </form>
        </div>
        <FooterSmall />
      </div>
    );
  }
}
