import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import NavBar from "../NavBar";
import ResentVerification from "./ResentVerification";
import { confirmUser, sendConfirm } from "../../utils/usersAPI";

import "../../css/confirmEmail.css";
import login_facebook from "../../assets/img/btn_login_facebook.png";
import login_twitter from "../../assets/img/btn_login_twitter.png";
import login_google from "../../assets/img/btn_login_google.png";
import login_linkedin from "../../assets/img/btn_login_linkedin.png";
//import { Nav } from "react-bootstrap";
import { apiUrl, readCookie } from "../../utils/utils";
//import { getActivatedEmailMessage } from "../../utils/messages";
const api = apiUrl();

export default function ConfirmEmail() {
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [displayResendMessage, setDisplayResendMessage] = useState(false);
  const [displayAlreadyConfirmed, setDisplayAlreadyConfirmed] = useState(false);

  const { token } = useParams();

  const handleConfirmEmail = async () => {
    setVerifying(true);
    const result = await confirmUser(token);
    console.log("Confirm email result: ", result);
    setVerifying(false);
    if (result.result === "Success") {
      setSuccess(true);
      setEmail(result.email);
    } else if (result.result === "Already Confirmed") {
      setErrorMessage(null);
      setDisplayAlreadyConfirmed(true);
    } else {
      setErrorMessage(result.message);
      if (result?.email) {
        setEmail(result.email);
      }
    }
  };

  const handleResendConfirm = async () => {
    const result = await sendConfirm(email);
    if (result.result === "Success") {
      setErrorMessage(null);
      setDisplayResendMessage(true);
    } else if (result.result === "Already Confirmed") {
      setErrorMessage(null);
      setDisplayAlreadyConfirmed(true);
    } else {
      setErrorMessage(result.message);
    }
  };

  useEffect(() => {
    if (token) {
      handleConfirmEmail();
    }
  }, []);

  const successMessage = () => {
    return (
      <div className="success-div">
        <div>Thank you for activating your account.</div>
        <div>Your email {email} has been verified. Yeaah!</div>
        <div>Now, you can begin your Dance4Healing journey by logging into your account.</div>
        <Link to="/login" className="confirm-btn">
          Log In
        </Link>
      </div>
    );
  };

  const alreadyConfirmedMessage = () => {
    return (
      <div className="success-div">
        <div>Your email is already confirmed - thank you!</div>
        <div>You can begin your Dance4Healing journey by logging into your account.</div>
        <Link to="/login" className="confirm-btn">
          Log In
        </Link>
      </div>
    );
  };

  const errorNoEmailMessage = () => {
    return (
      <div className="success-div">
        <div>There is an error confirming your email.</div>
        <div>Please login and choose to resend email confirmation if prompted.</div>
        <div>If you continue to run into errors, please email hello@dance4healing.com for assistance. Thank you!</div>
        <Link to="/login" className="confirm-btn">
          Log In
        </Link>
      </div>
    );
  };

  const resendMessage = () => {
    return (
      <div className="resend-div">
        <button onClick={handleResendConfirm} className="confirm-btn">
          Resend email confirmation
        </button>
        <p>Meanwhile, follow us on these social media websites down below!</p>
        <div className="icons">
          <a href="https://www.facebook.com/danceforhealing">
            <img className="confirm-img" src={login_facebook} alt="Facebook" />
          </a>
          <a href="http://www.twitter.com/dance4healing">
            <img className="confirm-img" src={login_twitter} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/dance4healing">
            <img className="confirm-img" src={login_linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="confirm">
      <NavBar />
      <div className="confirm-container">
        <h3>Email Confirmation</h3>
        {success ? successMessage() : errorMessage ? <div>{errorMessage}</div> : null}
        {verifying && <div>verifying your email...</div>}
        {errorMessage && email && resendMessage()}
        {errorMessage && !email && errorNoEmailMessage()}
        {displayResendMessage && <ResentVerification email={email} />}
        {displayAlreadyConfirmed && alreadyConfirmedMessage()}
      </div>
    </div>
  );
}
