import { Link } from "react-router-dom";
import "../css/footer.css";
import login_facebook from "../assets/img/btn_login_facebook.png";
import login_twitter from "../assets/img/btn_login_twitter.png";
import login_google from "../assets/img/btn_login_google.png";
import login_linkedin from "../assets/img/btn_login_linkedin.png";
import { apiUrl } from "../utils/utils";
import { useTranslation, Trans } from "react-i18next";
import { HelperDocsMenuList } from "./helpsupport/HelpMenus";
import FooterSmall from "./FooterSmall";
const earlyAccessEnabled = process.env.REACT_APP_EARLY_ACCESS_ENABLED === "true";
const api = apiUrl();

function Footer({ children }) {
  const { t } = useTranslation();
  // const alt_text_FB = "Log in with your social account on Facebook"
  return (
    <footer>
      {earlyAccessEnabled ? (
        <div>
          <br />
          <br />
        </div>
      ) : (
        <div>
          <p className="p-social">Log in with social media</p>
          <div className="icons">
            <a href={`${api}/auth/facebook`}>
              <img src={login_facebook} alt="" />
            </a>
            {/* <a href={`/`}>
          <img src={login_twitter} alt="" />
        </a> */}
            <a href={`${api}/auth/google`}>
              <img src={login_google} alt="" className="tour-login-options-google" />
            </a>
            {/* <img src={login_google} onClick={() => googleLogin()} alt="" /> */}
            <a href={`${api}/auth/linkedin`}>
              <img src={login_linkedin} alt="LinkedIn login" className="tour-login-options-linkedin" />
            </a>
            <span
              style={{
                position: "relative",
                float: "right",
                marginTop: "2rem",
              }}
            >
              {[<HelperDocsMenuList />][0]}
            </span>
          </div>
        </div>
      )}
      <div className="buttonarea">
        <Link to="/login" className="footer-btn btn-login">
          Log In
        </Link>
        <Link to="/register" className="footer-btn btn-signup">
          <span onClick={() => window.localStorage.clear()}>Sign Up</span>
        </Link>
      </div>

      <FooterSmall />
      <>{children}</>
    </footer>
  );
}

export default Footer;
