import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NavBar from "./NavBar";
import { Link, Navigate, useParams } from "react-router-dom";
import "../css/login.css";
import { checkResetToken, doPassReset } from "../utils/usersAPI";
import alertImg from "../assets/img/exclamation-triangle.svg";
import FooterSmall from "./FooterSmall";

export default function ResetSuccess(props) {
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch("password");
  const password2 = watch("password2");

  const [smallMessage, setSmallMessage] = useState("Checking reset token.");
  const [tokenSuccess, setTokenSuccess] = useState(false);
  const [displayResetLink, setDisplayResetLink] = useState(false);
  const [displayLoginLink, setDisplayLoginLink] = useState(false);

  useEffect(async () => {
    const result = await checkResetToken(token);
    const response = await result.json();
    if (result.ok) {
      setSmallMessage(response.message);
      setTokenSuccess(true);
      setDisplayResetLink(false);
    } else {
      setSmallMessage(response.errorMessage);
      setDisplayResetLink(true);
    }
  }, []);

  const onSubmit = async (data) => {
    const result = await doPassReset({ ...data, resetToken: token });
    const response = await result.json();

    if (result.ok) {
      setSmallMessage(response.message);
      setDisplayResetLink(false);
      setTokenSuccess(false);
      setDisplayLoginLink(true);
    } else {
      setSmallMessage(response.errorMessage || "There was an unexpected error.  Please try again.");
    }
  };

  return (
    <div>
      <NavBar />
      <div className="login-container">
        <h1>Reset Password</h1>
        {smallMessage ? (
          <p
            style={{
              border: "2px solid #e7f036",
              borderRadius: "6px",
              marginTop: 20,
              marginBottom: 20,
              padding: 15,
              fontFamily: '"Roboto", sans-serif',
              fontSize: 18,
              fontWeight: 300,
              letterSpacing: 1,
            }}
          >
            {smallMessage}
          </p>
        ) : null}
        {displayResetLink ? (
          <>
            <br />{" "}
            <Link to="/passwordreset">
              <button className="login-btn">Send password reset email</button>
            </Link>
          </>
        ) : null}
        {displayLoginLink ? (
          <>
            <br />{" "}
            <Link to="/login">
              <button className="login-btn">Proceed to Login</button>
            </Link>
          </>
        ) : null}
        {tokenSuccess ? (
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="signup-form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Enter Password"
                className={errors?.password ? "signup-form-control input-error" : "signup-form-control"}
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
                    message: "Password does not meet requirements",
                  },
                })}
              />
              <span className="password-message" id="message1">
                Password requirements are:
                <br /> Between 8 and 30 characters.
                <br /> At least 1 small letter.
                <br /> At least 1 capital letter.
                <br /> At least 1 number.
                <br /> At least 1 symbol.
              </span>
              {errors.password && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.password.message}</span>
                </div>
              )}
            </div>
            <div className="signup-form-group">
              <label htmlFor="password2">Confirm Password</label>
              <input
                id="password2"
                name="password2"
                type="password"
                placeholder="Confirm Password"
                className={errors?.password2 ? "signup-form-control input-error" : "signup-form-control"}
                {...register("password2", {
                  required: "Confirm password is required",
                  validate: (value) => value === password || "Confirm password does not match",
                })}
              />
              {errors.password2 && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>{errors.password2.message}</span>
                </div>
              )}
              {!errors.password2 && password && password2 && password !== password2 && (
                <div className="error-message">
                  <img className="error-img" src={alertImg} />
                  <span>Confirm password does not match</span>
                </div>
              )}
            </div>
            {/* harder font, like open sans or montserrat*/}
            <button
              type="submit"
              name="submit"
              style={{ marginTop: 36 }}
              // style={{ marginTop: 36, marginLeft: "-120px" }}
              className="login-btn"
            >
              Let's Dance!
            </button>
          </form>
        ) : null}
      </div>
      <FooterSmall />
    </div>
  );
}
