import { apiUrl } from "./utils";

const api = apiUrl();
//console.log("API URL: ", api);

let token = window.localStorage.getItem("token");

const headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  Authorization: token,
};

export const getWelcomeMessage = () =>
  fetch(`${api}/users/welcome`, { headers })
    .then((res) => res.json())
    .then((data) => data.welcomeMessage);

export async function checkResetToken(token) {
  return await fetch(`${api}/users/checkreset`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ resettoken: token }),
  });
}

export async function confirmUser(token) {
  return fetch(`${api}/email/confirm`, {
    method: "POST",
    headers: headers,
    //body: JSON.stringify({ id: id.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, "$1") }),
    body: JSON.stringify({ token: token }),
  }).then((data) => data.json());
}

// export async function isEmailConfirmed(email) {
//   return fetch(`${api}/email/isConfirmed`, {
//     method: "POST",
//     headers: headers,
//     body: JSON.stringify({
//       email: email.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, "$1"),
//     }),
//   }).then((data) => data.json());
// }

export async function sendWelcome(email) {
  console.log("Sending welcome email to " + email);
  return fetch(`${api}/email/sendWelcome`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      email: email.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, "$1"),
    }),
  }).then((data) => data.json());
}

export async function sendConfirm(email) {
  console.log("Sending confirm email to " + email);
  return fetch(`${api}/email/sendConfirm`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      email: email?.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, "$1"),
    }),
  }).then((data) => data.json());
}

export async function doPassReset(values) {
  const response = await fetch(`${api}/users/passwordreset`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(values),
  });
  return response;
}

export async function loginUser(credentials) {
  const response = await fetch(`${api}/auth/login`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(credentials),
  });
  return response;
}

export async function resetPass(values) {
  const response = await fetch(`${api}/users/forgotpassword`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(values),
  });
  return response;
}

export async function updateUser(info) {
  return fetch(`${api}/users/update`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(info.values),
  }).then((data) => data.json());
}

export async function registerUser(body) {
  return fetch(`${api}/users/register`, {
    method: "POST",
    headers: headers,
    // body: JSON.stringify(credentials.values),
    body: JSON.stringify(body),
  })
    .then((data) => data.json())
    .then((error) => {
      return error;
    });
}

export const ensureAuth = async () => {
  try {
    const response = await fetch(`${api}/auth/ensure`, {
      method: "GET",
      headers: headers,
      credentials: "include",
      retryOn: [401],
      retries: 2,
      retryDelay: 1500,
    });
    // console.table(response);
    if (response.status === 200) {
      const data = await response.json();
      // console.log("data after ensure in api: ", data);
      window.localStorage.setItem("authenticated", data.success);
      window.localStorage.setItem("username", data.username);
      return data;
    } else {
      return false;
    }
  } catch (err) {
    console.log("ensureAuth Error: ", err);
    return err;
  }
};

export const getUserData = async () =>
  fetch(`${api}/users/userdata`, {
    method: "GET",
    headers: headers,
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Something went wrong ...");
    }
  });

export const saveEmotion = async (values) =>
  fetch(`${api}/users/emotion`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(values),
  }).then((response) => response.json());

export const logVideoEvent = async (values) => {
  //console.log("IN usersAPI log video: ", values);
  fetch(`${api}/videos`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(values),
  }).then((response) => response.json());
  // .then(json => console.log("Logged event: ", json));
};

export const updateImage = (values) =>
  fetch(`${api}/users/updateimage`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(values),
  }).then((response) => {
    // console.log("update error response: ", response.errorMessage);
    // console.log("update success response: ", response.Message)
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Something went wrong ...");
    }
  });

export const googleLogin = () => {
  fetch(`${api}/auth/google`, {
    method: "GET",
    headers: headers,
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      window.localStorage.setItem("authenticated", response.success);
      window.localStorage.setItem("username", response.username);
      window.localStorage.setItem("token", response.token);
    }
    return response;
  });
};
